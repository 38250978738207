import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Hero from "../components/Hero/HeroSplit";
import ValueProps from "../components/Repeating/ValueProps";
import About from "../components/Repeating/About";
import Testimonials from "../components/Repeating/Testimonials";
import Resources from "../components/Repeating/Resources";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Layout>
      <SearchEngineOptimization
        title="Second Home Buyer in Minnesota | Thistle Financial"
        description="Trust Thistle Financial to find you the right loan when you're ready to purchase a vacation home or investment property. Second home buyers are our specialty."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <Hero
        image={data.heroDesktop.childImageSharp.gatsbyImageData}
        imageAlignment="right"
        textWidth="1/2"
        imageWidth="1/2"
        minHeight="md:min-h-[626px]"
        padding="pb-16 md:pb-0"
      >
        <h1>Second Home Buyer in Minnesota</h1>
        <p>
          At Thistle, we specialize in the second home buyer who’s interested in
          purchasing a vacation house or investment property. Buying a second
          home that's not your primary residence has its unique considerations.
          You can trust us to lead the way.
        </p>
        <ButtonSolid href="/request-rates/" text="Request Rates" />
      </Hero>

      <section className="bg-primary-50 py-20 md:py-32">
        <div className="container">
          <header className="max-w-2xl mb-16 md:mb-20">
            <h2>What to Take Into Consideration</h2>
          </header>

          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-20">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4 hidden md:block relative after:content after:absolute after:right-0 after:top-0 after:w-px after:h-full after:bg-primary-600/30">
              <li>
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="Does it Fit Your Budget?"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="Lenders Are Typically More Stringent"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="Know The Tax Implications"
                  stripHash
                  className="relative font-body font-bold tracking-widest uppercase"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <div
                id="anchor-1"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Does it Fit Your Budget?</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  As a second home buyer, you'll have two mortgage payments
                  (unless you own your primary home free and clear). Therefore,
                  you'll want to ensure you have the budget to support it. And
                  don't forget about other expenses such as utilities and
                  maintenance.
                </p>
              </div>

              <div
                id="anchor-2"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6 mb-10 lg:mb-14"
              >
                <h3 className="mb-1">Lenders Are Typically More Stringent</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  Lenders consider a second home buyer mortgage a higher risk,
                  which means you may need a larger down payment, and the
                  interest rate will likely be higher. Some lenders may also
                  require that you have a cash reserve to cover six months of
                  expenses. Or, if investing, you may be required to show you
                  have property management experience.
                </p>
              </div>

              <div
                id="anchor-3"
                className="bg-white shadow-4xl pt-8 md:pt-10 px-4 md:px-10 pb-4 md:pb-6"
              >
                <h3 className="mb-1">Know The Tax Implications</h3>
                <div className="w-18 h-0.5 bg-primary-600 mb-6"></div>
                <p>
                  While there are tax benefits to owning a vacation home or
                  investment property, such as several deductions that you can
                  take, some tax implications can impact your profitability. At
                  Thistle, we’ll help you understand how taxes play a role in
                  your investment.
                </p>
              </div>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <Resources />
      <ValueProps />
      <About className="mb-0" />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Second Home Buyers.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Second Home Buyers.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: { eq: "2.2 Second Home buyers/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 680)
      }
    }
  }
`;
export default Page;
